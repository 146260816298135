<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center" dense>
      <v-col cols="12" sm="8" md="4" lg="2">
        <v-card elevation="3">
          <v-card-text>
              <v-form >
                <v-text-field
                    v-model="username"
                    label="Логин"
                    solo
                    name="login"
                ></v-text-field>
                <v-text-field v-model="pass" label="Пароль" solo name="password" type="password" clearable @keydown.enter="login"></v-text-field>
                <v-btn x-large block  @click.stop="login" :loading="reqLoading" :color="btnColor">Вход</v-btn>
              </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {apiLogin} from "../api/api";

export default {
  name: "Authorization",
  data: function () {
    return {
      username: '',
      pass: '',
      reqLoading: false,
      btnColor: 'primary',
    }
  },
  watch: {
    authorized: function (val) {
      if (val) {
        if (this.$route.query && this.$route.query.redirect) {
          this.$router.push({ path: this.$route.query.redirect })
        } else {
          this.$router.push({ path: '/' })
        }
      }
    },
  },
  computed: {
    authorized() {
      return this.$store.getters.isAuthorized;
    },
  },
  methods: {
    login() {
      this.reqLoading = true
      apiLogin(this.username, this.pass)
      .then((res) => {
        this.reqLoading = false
        if (res.status === 200) {
          this.$store.commit('setNewLogin', res.body)
        } else {
          this.btnColor = 'error'
          console.log(res)

        }
      })
    }
  },
  mounted() {
    if (this.authorized) {
      this.$router.push({ path: '/' })
    }
  },
}
</script>

<style scoped>

</style>